html, body {
    width: 100vw;
    height: 100vh;
    padding: 0;
    margin: 0;
    overflow: hidden;
}

.App {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100vw;
    height: 100vh;
    padding: 0;
    margin: 0;
}

.memory {
    flex-grow: 1;
    overflow: auto;
    border-bottom: 0.3rem solid #ececec;
}

.result {
    background: #ececec;
    border-radius: 0.6rem;
    text-align: center;
    padding: 1rem;
    margin: 0.3rem auto;
}

.result_current {
    background: #7595ff;
    color: white;
}

.result_empty {
    background: none;
}

.result_min {
    background: #cceecc;
}

.result-value {
    font-size: 1.5rem;
    font-weight: bold;
}

.keyboards {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.keyboard {
    width: 50vw;
}

.input {
    background: #ececec;
    border: 0.3rem solid white;
    border-bottom: none;
    border-radius: 0.6rem 0.6rem 0 0;
    box-sizing: border-box;
    text-align: center;
    width: 100%;
    padding: 1rem;
    cursor: pointer;
    font-size: 1.2rem;
    font-weight: bold;
}
